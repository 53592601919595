<template lang="pug">
  .head(v-if="!$store.state.pcshow" to="/")
    router-link(to="/")
      img(src="../assets/img/index/h5logo.png")


  .header(v-else)
    div.headerCon
      img(src="../assets/img/index/img (6).png")
      ul.ul
        li.li(v-for="(item, index) in list" :key="index") 
          div(v-if="!item.children") 
            router-link(:to="item.push")
              span(:class="item.actived ? 'li1ACtive' : 'li'")    {{ item.name}}
          div(v-if="item.children" ) 
            span(:class="item.actived==true ? 'li1ACtive' : 'li'")  {{ item.name }}
            ul.child(v-show="item.children")
              li.liItems(v-for="(ite, inde) in item.children" :key="inde") 
                router-link(:to="ite.push")
                  span.childs(@click="chooseCurrent(index)") {{ ite.name }}
  
</template>

<script>
export default{
  name: 'Headers',
  data() {
    return {
      list: [
        {
          actived:true,
          name: "首页",
          push: '/'
        },
        // {
        //   actived:false,
        //   name: "集团简介",
        //   push: '/companyIntro'
        // },
        //  {
        //   actived:false,
        //   name: "企业资讯",
        //   push: '/companyInfo'
        // },
        // {
        //   actived:false,
        //   name: "组织架构",
        //   push: '/structure'
        // },
        //  {
        //   actived:false,
        //   name: "中科医创",
        //   push: '/zhongkeYC'
        // },
        //  {
        //   actived:false,
        //   name: "中鑫广汇",
        //   push: '/zhongxinGH'
        // },
        //  {
        //   actived:false,
        //   name: "亿联体事业部",
        //   push: '/yilianGl'
        // },
        //  {
        //   actived:false,
        //   name: "产业园区",
        //   push: '/chanyeYQ'
        // },
      ]
    }
  },
  methods:{
    chooseCurrent(index){
      let list=this.list;
      for(let i=0;i<list.length;i++){
        if(index==i){
          list[i].actived=true;
        }else{
          list[i].actived=false;
        } 
      }
      this.list=list;
    }
  },
  watch:{
    $route(to,from){
        let list=this.list;
        for(let i=0;i<list.length;i++){
          if(list[i].push==to.path){
            list[i].actived=true;
          }else{
            list[i].actived=false;
          }
        }
        this.list=list;
    }
  },
}
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px)
    .head
      width 7.5rem
      height 1rem

      img
        display block
        width 0.69rem
        height 0.82rem
        padding 0.09rem 0 0 0.33rem
        
  @media screen and (min-width: 960px)
    .header
      width 100%
      height 100px
      background-color #FFFFFF
      z-index 9999
      position fixed
      top 0px
      left 0px
    .headerCon
          width 1280px
          height 100px
          margin 0 auto
          display flex
          align-items center
          img
            width 75px
            height 75px
          .ul
            display flex
            height 108px
            margin-left: 165px
            .childs:hover
                color #FFFFFF
            .li1ACtive
                height 104px
                box-sizing border-box
                border-bottom 4px solid #0085D0
                color #0085D0
                .child
                  display block
            .li
              width 100px
              height 104px
              box-sizing border-box
              line-height 108px
              text-align center
              cursor pointer
              position relative 
              color #666666
              span
                display block
              &:hover
                box-sizing border-box
                border-bottom 4px solid #0085D0
                color #0085D0
                .child
                  display block
              .child
                position absolute
                top 95px
                z-index 11
                display none
                width 160px
                color #666666
                li
                  width 160px
                  height 48px
                  border-radius 6px
                  margin-left -30px
                  line-height 48px
                  background #fff
                  span
                    display block
                  &:hover
                    width 160px
                    height 48px
                    color #FFFFFF 
                    background #0085D0;  
</style>