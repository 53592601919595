<template lang="pug">
  .foot(v-if="!$store.state.pcshow")
    .foottop
      router-link(to="/companyIntro")
        .li 关于集团
          //- router-link(to="/companyIntro")
          //-   .ol 集团简介
          //- router-link(to="/structure")
          //-   .ol 组织架构
      router-link(to="")
        .li 核心业务
      router-link(to="")
        .li 孵化企业
          //- router-link(to="/zhongkeYC")
          //-   .ol 中科医创
          //- router-link(to="/zhongxinGH")
          //-   .ol 中鑫广汇
          //- router-link(to="/yilianGl")
          //-   .ol 亿联管理
          //- router-link(to="/chanyeYQ")
          //-   .ol 产业园区
      router-link(to="/companyInfo")
        .li 企业资讯
      router-link(to="") 
        .li 合作伙伴
    .calme
      .caltitle 联系我们
      .caladdr 中国 · 上海
      .caldaaress 上海市闵行区申昆路1999号星月德必5号楼503室
    .hfotr Copyright © 2021上海亿联体创业孵化器管理有限公司备案号：
      a(href="https://beian.miit.gov.cn" class="alick2") 沪ICP备18024009号-1
      p(@click="herfs2" style="cursor: pointer;display: flex;align-items: center;justify-content: center;")
        img(src="../assets/img/ba.png" style="float:left;")
        span 沪公网安备：
        span 31011202014447号
  .footer(v-else)
    .flix
      .left
        router-link(to="/companyIntro")
          .li 关于集团
            router-link(to="/companyIntro")
              .ol 集团简介
            router-link(to="/structure")
              .ol 组织架构
        router-link(to="")
          .li 核心业务
        router-link(to="")
          .li 孵化企业
            //- router-link(to="/zhongkeYC")
            //-   .ol 中科医创
            router-link(to="/zhongxinGH")
              .ol 中鑫广汇
            router-link(to="/yilianGl")
              .ol 亿联管理
            router-link(to="/chanyeYQ")
              .ol 产业园区
        router-link(to="/companyInfo")
          .li 企业资讯
        router-link(to="") 
          .li 合作伙伴
        router-link(to="")
          .li 联系我们
      //- .min
      //-   img(src="../assets/img/index/img (16).png")
      .right
      //- p 服务热线：000-000000
        img(src="../assets/img/index/img (16).png")
    .fotr Copyright © 2021上海亿联体创业孵化器管理有限公司&nbsp;&nbsp;
      p(style="padding-top:5px;") 备案号：
        a(href="https://beian.miit.gov.cn" class="alick") 沪ICP备18024009号-1
      div(style="width:100%;margin:0 auto;cursor: pointer;height: 30px;line-height: 30px;" @click="herfs2")
        span(style="display:inline-block;text-decoration:none;height:20px;line-height:20px;")
        p(style="float:left;height:30px;line-height:30px;margin: 0 auto; color:#939393;width:100%;display: flex;align-items: center;justify-content: center;")
          img(src="../assets/img/ba.png" style="float:left;")
          span.alick2 沪公网安备：
          span.alick 31011202014447号
</template>

<script>
export default {
  name: "Footers",
  data() {
    return {};
  },
  methods:{
    herfs2(){
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014447','_blank')
    }
  }
};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) 
    .foot
      background #212832
      width 7.15rem
      padding-left 0.35rem

      .foottop
        display flex
        margin-right 0.35rem
        justify-content space-between
        padding-top 0.31rem

        .li
          text-align center
          font-size 0.24rem
          font-family Source Han Sans CN
          font-weight 500
          color #FFFFFF
          
          .ol
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 300
            color: #FFFFFF;
            margin-bottom: 0.16rem

          .ol:first-child
            margin-top 0.23rem
      .calme
        margin-top 0.32rem

        .caltitle
          font-size: 0.24rem;
          font-family Source Han Sans CN
          font-weight 500
          color: #FFFFFF;
        
        .caladdr
          font-size 0.24rem
          color #FFFFFF
          margin 0.2rem 0
          font-weight normal
          font-family: SourceHanSansCN-Light;

        .caldaaress
          font-size: 0.24rem;
          color #FFFFFF
          font-weight normal 
          font-family: SourceHanSansCN-Light;
      
      .hfotr
        margin-right: 0.4rem
        margin-left :0.23rem
        text-align: center
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFEFE;
        margin-top: 0.6rem
        line-height: 0.5rem
        padding-bottom: 0.24rem
        .alick2
          color: #FFFEFE;
        

        

  
  @media screen and (min-width: 960px) {
    .alick{
      color: #a6a7ab;
      &:hover{
        color: #008cff;
      }
    }
    .footer {
      background: #212832;
      color: #fff;
      padding-top: 120px;
      padding-bottom: 26px;

      .fotr {
        text-align: center;
        color: #A6A7AB;
        margin-top: 126px;
      }

      .flix {
        display: flex;
        width: 1280px;
        margin: 0 auto;
        justify-content: space-between;

        .left {
          display: flex;
          width: 794px;
          justify-content: space-between;
          font-size: 22px;

          .li {
            color: #fff;
            width: 88px;
            text-align: center;
            cursor: pointer;

            &:hover {
              color: #0085D0;
            }

            .ol {
              color: #fff;
              height: 34px;
              cursor: pointer;
              line-height: 34px;
              font-size: 16px;

              &:hover {
                color: #0085D0;
                .li {
                  color:#fff
                }
              }
            }

            .ol:first-child {
              margin-top: 15px;
            }
          }
        }

        .min {
          width: 140px;
          height: 140px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .right {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 230px;
          font-size: 22px;

          p {
            display: block;
            width: 230px;
            font-size: 21px;
            margin-bottom: 25px;
          }

          img {
            width: 180px;
            height: 95px;
          }
        }
      }
    }
  }
</style>