import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export const constroutes = [
  {
    path: '/',
    name: 'index', //登录页
    component: (resolve) => require(['@/views/index'], resolve),
    // meta: {
    //   title: '亿联体集团',
    //   content:{
    //     keywords:'"亿联体集团","上海亿联体","亿联体","亿联体创业孵化器"',
    //   },
    // }
    // component: (resolve) => require(['@/views/index'], function () {
    //   console.log(11);
    //   console.log(resolve);
    //   resolve()
    // }),
  },
  {
    path: '/zhongkeYC',
    name: 'zhongkeYC', // 孵化企业-中科医创
    component: (resolve) => require(['@/views/hatchCompany/zhongkeYC.vue'], resolve),
  },
  {
    path: '/zhongxinGH',
    name: 'zhongxinGH', // 孵化企业-中鑫广汇
    component: (resolve) => require(['@/views/hatchCompany/zhongxinGH.vue'], resolve),
  },
  {
    path: '/yilianGl',
    name: 'yilianGl', // 孵化企业-亿联体管理/事业部
    component: (resolve) => require(['@/views/hatchCompany/yilianGl.vue'], resolve),
  },
  {
    path: '/chanyeYQ',
    name: 'chanyeYQ', // 孵化企业-产业园区
    component: (resolve) => require(['@/views/hatchCompany/chanyeYQ.vue'], resolve),
  },
  {
    path: '/companyInfo',
    name: 'companyInfo', // 企业资讯
    component: (resolve) => require(['@/views/companyInfo'], resolve),
    // children: [
    //   {
    //     path: 'companyInfoItem',
    //     name: 'companyInfoItem', // 企业资讯二级
    //     component: (resolve) => require(['@/views/companyInfo/companyInfoItem.vue'], resolve),
    //   },
    // ],
  },
  {
    path: '/companyInfoItem',
    name: 'companyInfoItem', // 企业资讯二级
    component: (resolve) => require(['@/views/companyInfo/companyInfoItem.vue'], resolve),
  },
  {
    path: '/structure',
    name: 'structure', // 组织架构
    component: (resolve) => require(['@/views/structure/index.vue'], resolve),
  },
  {
    path: '/companyIntro',
    name: 'companyIntro', // 公司简介
    component: (resolve) => require(['@/views/companyIntro/index.vue'], resolve),
  },
  {
    path: '/investment',
    name: 'investment', // 公司简介--产品投资
    component: (resolve) => require(['@/views/companyIntro/investment.vue'], resolve),
  },
  {
    path: '/Incubator',
    name: 'Incubator', // 公司简介--孵化器平台
    component: (resolve) => require(['@/views/companyIntro/Incubator.vue'], resolve),
  },
  {
    path: '/medicalcare',
    name: 'medicalcare', // 公司简介--医疗联合体>>
    component: (resolve) => require(['@/views/companyIntro/medicalcare.vue'], resolve),
  },
  {
    path: '/wj',
    name: 'wj', // 公司简介--文教创新
    component: (resolve) => require(['@/views/companyIntro/wj.vue'], resolve),
  },
  {
    path: '/healthy',
    name: 'healthy', // 公司简介--生命健康集群平台
    component: (resolve) => require(['@/views/companyIntro/healthy.vue'], resolve),
  },
];
const createRouter = () =>
  new VueRouter({
    mode: 'hash', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constroutes,
  });

const router = createRouter();

export default router;
