import Vue from 'vue'
import store from '../../store'
(function (doc, win) {
    var docEl = doc.documentElement
    var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
    var recalc = function () {
      var clientWidth = docEl.clientWidth
      if(clientWidth<960){
        if (!clientWidth) return
        docEl.style.fontSize = (clientWidth / 7.5) + 'px'
        store.state.pcshow = false
      }else{
        docEl.style =  ''
        store.state.pcshow = true
      }
      
    }
   
    if (!doc.addEventListener) return
    win.addEventListener(resizeEvt, recalc, false)
    doc.addEventListener('DOMContentLoaded', recalc, false)
  })(document, window)